import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import AllRoutes from './AllRoutes';
import Admin from './pages/Admin/Admin';

import AdminLayout from './components/Layouts/AdminLayout';

import AllBlogs from './pages/Blogs/AllBlogs';
import BlogPage from './pages/Blogs/BlogPage';
import BlogsByCategory from './pages/Blogs/BlogsByCategory';
import AllCaseStudies from './pages/Case Studies/AllCaseStudies';
import CaseStudyPage from './pages/Case Studies/CaseStudyPage';
import CaseStudiesByCategory from './pages/Case Studies/CaseStudiesByCategory';
import AuthorProfile from './pages/Author/AuthorProfile';
import NotFound from './pages/NotFound';

import HomePage from './components/webpages/home';
import Retail from './components/webpages/retail';
import Travel from './components/webpages/travel';
import Hotel from './components/webpages/hotels';
import ProfitCalcPage from './components/webpages/profitCalcPage';
import About from './components/webpages/about';
import AboutContactPage from './components/webpages/AboutContactPage';
import Careers from './components/webpages/careers';
import Investors from './components/webpages/investors';
import InvestorsContactPage from './components/webpages/InvestorsContactPage';
import Partner from './components/webpages/partner';
import PartnerContactPage from './components/webpages/PartnerContactPage';
import ContactPage from './components/webpages/contact-page';
import RetailContactPage from './components/webpages/retail-demo';
import TravelContactPage from './components/webpages/travel-demo';
import News from './components/webpages/news';
import RouteReports from './components/webpages/routeReports';
import WhyUs from './components/webpages/whyus';
import Temp from './pages/Temp';
import PrivacyPolicy from './components/webpages/privacy-policy';
import CookiePolicy from './components/webpages/cookie-policy';
import TermsAndConditions from './components/webpages/terms-and-conditions';

import './assets/css/base.css'
import CommonLayout from './components/Layouts/CommonLayout';
import RoadAheadTalks from './pages/RoadAheadTalks/RoadAheadTalks';
import RoadAheadNewsletter from './pages/RoadAheadTalks/RoadAheadNewsletter';
import FormDevelopment from './pages/RoadAheadTalks/FormDevelopment';
import Webinar from './pages/RoadAheadTalks/Webinar';
import AutomatedPricing from './components/webpages/automatedpricing';

import ScrollToTop from "../src/components/common/ScrollToTop";
import UnifiedPricing from './components/webpages/unifiedpricing';
import GoalBasedPricing from './components/webpages/goalbasedpricing';
import CompetitionIntelligence from './components/webpages/competitionIntelligence';
import PricingConsulting from './components/webpages/pricingconsulting';
import OtherDrivers from './components/webpages/otherdrivers';
import AutomatedDp from './components/webpages/automatedDp';
import PricingAutomation from './components/webpages/pricingAutomation';
import CompPriceTrack from './components/webpages/compPriceTracking';
import MarketPricing from './components/webpages/marketPricing';
import Electronics from './components/webpages/electronics';
import HWB from './components/webpages/health-wellness-beauty';
import FoodConsumable from './components/webpages/foodConsumable';
import FashionApparel from './components/webpages/fashionApparel';
import MarketIntelligence from './components/webpages/market-intelligence';
import BrandSellers from './components/webpages/brand-sellers';
import Retailers from './components/webpages/retailers';
import CXODecisionMakers from './components/webpages/cxo-decision-makers';
import CategoryManagers from './components/webpages/category-managers';
import LearningMaterials from './components/webpages/learning-materials';

import RetailStaticPage from './components/retail/retailStaticPage';
import HotelFaq from './components/webpages/hotelfaq';
import HotelCustomer from './components/webpages/hotelCustomer';
import HotelsContactPage from './components/webpages/HotelsContactPage';

import HotelsProfitCalculator from './components/hospitality/ProfitCalculator';

import Redirect from './Redirect';
import { ToastContainer } from 'react-toastify';

import { adminURL } from './global';
import Glossary from './components/webpages/Glossary';

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* Home route */}
          <Route exact path={AllRoutes.home} element={<CommonLayout fixed={true}><HomePage /></CommonLayout>} />
          <Route exact path={AllRoutes.bannedRoute1} element={<CommonLayout fixed={true}><HomePage /></CommonLayout>} />
          <Route exact path={AllRoutes.bannedRoute2} element={<CommonLayout fixed={true}><HomePage /></CommonLayout>} />
          <Route exact path={AllRoutes.bannedRoute3} element={<CommonLayout fixed={true}><HomePage /></CommonLayout>} />
          <Route exact path={AllRoutes.bannedRoute4} element={<CommonLayout fixed={true}><HomePage /></CommonLayout>} />
          <Route exact path={AllRoutes.retail} element={<CommonLayout fixed={true}><Retail /></CommonLayout>} />
          <Route exact path={AllRoutes.travel} element={<CommonLayout fixed={true}><Travel /></CommonLayout>} />
          <Route exact path={AllRoutes.hotel} element={<CommonLayout fixed={true}><Hotel /></CommonLayout>} />
          <Route exact path={AllRoutes.ProfitCalculatorPage} element={<CommonLayout fixed={true}><ProfitCalcPage /></CommonLayout>} />
          <Route exact path={AllRoutes.about} element={<CommonLayout fixed={true}><About /></CommonLayout>} />
          <Route exact path={AllRoutes.aboutcontact} element={<AboutContactPage />} />
          <Route exact path={AllRoutes.investors} element={<CommonLayout fixed={true}><Investors /></CommonLayout>} />
          <Route exact path={AllRoutes.investorscontact} element={<InvestorsContactPage />} />
          <Route exact path={AllRoutes.partner} element={<CommonLayout fixed={true}><Partner /></CommonLayout>} />
          <Route exact path={AllRoutes.partnercontact} element={<PartnerContactPage />} />
          <Route exact path={AllRoutes.careers} element={<CommonLayout fixed={true}><Careers /></CommonLayout>} />
          <Route exact path={AllRoutes.contact} element={<ContactPage />} />
          <Route exact path={AllRoutes.retailcontact} element={<RetailContactPage />} />
          <Route exact path={AllRoutes.travelcontact} element={<TravelContactPage />} />
          <Route path={AllRoutes.news} element={<CommonLayout fixed={true}><News /></CommonLayout>} />
          <Route path={AllRoutes.routereports} element={<CommonLayout fixed={true}><RouteReports /></CommonLayout>} />
          <Route path={AllRoutes.glossary} element={<CommonLayout fixed={true}><Glossary/></CommonLayout>}></Route>
          <Route path={AllRoutes.retailwhyus} element={<CommonLayout fixed={true}><WhyUs /></CommonLayout>} />
          <Route path={AllRoutes.automatedpricingpage} element={<CommonLayout fixed={true}><AutomatedPricing/></CommonLayout>} />
          <Route path={AllRoutes.unifiedpricingpage} element={<CommonLayout fixed={true}><UnifiedPricing/></CommonLayout>} />
          <Route path={AllRoutes.goalbasedpage} element={<CommonLayout fixed={true}><GoalBasedPricing/></CommonLayout>} />
          <Route path={AllRoutes.competitionintpage} element={<CommonLayout fixed={true}><CompetitionIntelligence/></CommonLayout>} />
          <Route path={AllRoutes.pricingconsultpage} element={<CommonLayout fixed={true}><PricingConsulting/></CommonLayout>} />
          <Route path={AllRoutes.otherdriver} element={<CommonLayout fixed={true}><OtherDrivers/></CommonLayout>} />
          <Route path={AllRoutes.automated_dp} element={<CommonLayout fixed={true}><AutomatedDp/></CommonLayout>} />
          <Route path={AllRoutes.pricingautomation} element={<CommonLayout fixed={true}><PricingAutomation/></CommonLayout>} />
          <Route path={AllRoutes.comppricetrack} element={<CommonLayout fixed={true}><CompPriceTrack/></CommonLayout>} />
          <Route path={AllRoutes.marketpricing} element={<CommonLayout fixed={true}><MarketPricing/></CommonLayout>} />
          <Route path={AllRoutes.electronics} element={<CommonLayout fixed={true}><Electronics/></CommonLayout>} />
          <Route path={AllRoutes.hwb} element={<CommonLayout fixed={true}><HWB/></CommonLayout>} />
          <Route path={AllRoutes.food} element={<CommonLayout fixed={true}><FoodConsumable/></CommonLayout>} />
          <Route path={AllRoutes.fashion} element={<CommonLayout fixed={true}><FashionApparel/></CommonLayout>} />
          <Route path={AllRoutes.marketintel} element={<CommonLayout fixed={true}><MarketIntelligence/></CommonLayout>} />
          <Route path={AllRoutes.brandseller} element={<CommonLayout fixed={true}><BrandSellers/></CommonLayout>} />
          <Route path={AllRoutes.retailers} element={<CommonLayout fixed={true}><Retailers/></CommonLayout>} />
          <Route path={AllRoutes.cxodecision} element={<CommonLayout fixed={true}><CXODecisionMakers/></CommonLayout>} />
          <Route path={AllRoutes.categorymanagers} element={<CommonLayout fixed={true}><CategoryManagers/></CommonLayout>} />
          <Route path={AllRoutes.hotelfaq} element={<CommonLayout fixed={true}><HotelFaq/></CommonLayout>} />
          <Route path={AllRoutes.hotelcustomer} element={<CommonLayout fixed={true}><HotelCustomer/></CommonLayout>} />
          <Route path={AllRoutes.hotelcontact} element={<HotelsContactPage/>} />
          {/* Admin routes */}
          <Route path={`/niyantran-panel/${adminURL}/*`} element={<Admin/>} />

          {/* Blogs routes */}
          <Route exact path={AllRoutes.researcharticles} element={<CommonLayout fixed={true}><AllBlogs /></CommonLayout>} />
          <Route path='/research-articles/category/:cat' element={<CommonLayout fixed={true}><BlogsByCategory /></CommonLayout>} />
          <Route path='/research-articles/:blogId' element={<CommonLayout fixed={false}><BlogPage /></CommonLayout>} />
          
          {/* Case Study routes */}
          <Route exact path={AllRoutes.casestudies} element={<CommonLayout fixed={true}><AllCaseStudies /></CommonLayout>} />
          <Route exact path='/case-studies/category/:cat' element={<CommonLayout fixed={true}><CaseStudiesByCategory /></CommonLayout>} />
          <Route path='/case-studies/:caseStudyId' element={<CommonLayout fixed={false}><CaseStudyPage /></CommonLayout>} />

          {/* Road Ahead Talks routes */}
           <Route path={AllRoutes.roadaheadtalks} element={<CommonLayout fixed={true}><RoadAheadTalks /></CommonLayout>} />
           <Route path={AllRoutes.roadaheadnewsletter} element={<CommonLayout fixed={true}><RoadAheadNewsletter /></CommonLayout>} />
           <Route path='/road-ahead-talks/:url' element={<CommonLayout fixed={true}><Webinar /></CommonLayout>} />
           <Route path='/form-development' element={<FormDevelopment />} />
          

          {/* Learning Materials */}
          <Route exact path={AllRoutes.learningmaterials1} element={<LearningMaterials role='software-developer' />} />
          <Route exact path={AllRoutes.learningmaterials2} element={<LearningMaterials role='full-stack-developer' />} />
          <Route exact path={AllRoutes.learningmaterials3} element={<LearningMaterials role='dei' />} />
          <Route exact path={AllRoutes.learningmaterials4} element={<LearningMaterials role='product-analyst' />} />
          <Route exact path={AllRoutes.learningmaterials5} element={<LearningMaterials role='marketing' />} />
          <Route exact path={AllRoutes.learningmaterials6} element={<LearningMaterials role='qa' />} />

          {/* Author profile */}
          {/* <Route path='/authors/:authorId' element={<AuthorProfile />} /> */}

          {/* Temp route */}
          {/* <Route path='/temp' element={<Temp />} /> */}

          {/* Legal */}
          <Route path={AllRoutes.privacy} element={<CommonLayout fixed={true}><PrivacyPolicy /></CommonLayout>} />
          <Route path={AllRoutes.cookie} element={<CommonLayout fixed={true}><CookiePolicy /></CommonLayout>} />
          <Route path={AllRoutes.terms} element={<CommonLayout fixed={true}><TermsAndConditions /></CommonLayout>} />

          {/* Redirection routes */}
          <Route path='/solutions' element={<Redirect toUrl={AllRoutes.retail} />} />
          <Route path='/solutions/retail' element={<Redirect toUrl={AllRoutes.retail} />} />
          <Route path='/solutions/retail/*' element={<Redirect toUrl={AllRoutes.retail} />} />
          <Route path='/solutions/travel' element={<Redirect toUrl={AllRoutes.travel} />} />
          <Route path='/solutions/travel/*' element={<Redirect toUrl={AllRoutes.travel} />} />
          <Route path='/solutions/*' element={<Redirect toUrl={AllRoutes.retail} />} />
          {/* <Route path='/sciative-blogs/*' element={<Redirect toUrl={AllRoutes.researcharticles} />} /> */}
          <Route path='/blogs/' element={<Redirect toUrl={AllRoutes.researcharticles} />} />
          <Route path='/sciative-blogs/' element={<Redirect toUrl={AllRoutes.researcharticles} />} />


          <Route path={AllRoutes.retailStaticPage} element={<RetailStaticPage />} />
        
          {/* new blogs start */}
          <Route path='/blog/2023-beauty-market-pricing-trends-insights-and-dynamics/' element={<Redirect toUrl="/research-articles/2023-beauty-market-pricing-trends-insights-dynamics" />} />
          <Route path='/blog/republic-day-sale-duel-2024-a-comparative-analysis-of-amazon-and-flipkart/' element={<Redirect toUrl="/research-articles/republic-day-sale-duel-2024-a-comparative-analysis-of-amazon-and-flipkart" />} />
          <Route path='/blog/brand-health-check-for-electronics-listings-in-the-market/' element={<Redirect toUrl="/research-articles/brand-health-tracking-for-electronics-listings-in-the-market" />} />
          <Route path='/blog/do-consumer-ratings-affect-your-beauty-brands-buzz-in-the-market/' element={<Redirect toUrl="/research-articles/do-consumer-ratings-affect-your-beauty-brands-buzz-in-the-retail-market" />} />
          <Route path='/blog/share-of-search-the-essential-shelf-for-digital-success/' element={<Redirect toUrl="/research-articles/share-of-search-the-essential-shelf-for-digital-success" />} />
          <Route path='/blog/cracking-the-festive-code-how-retailers-play-with-pricing-and-promotions/' element={<Redirect toUrl="/research-articles/how-retailers-play-with-pricing-in-festive-period" />} />
          <Route path='/blog/the-green-revolution-in-retail-seeking-sustainability-in-2024/' element={<Redirect toUrl="/research-articles/seeking-sustainability-in-retail-2024" />} />
          <Route path='/blog/from-escalation-to-celebration-decoding-electronics-inflation-on-amazon/' element={<Redirect toUrl="/research-articles/from-escalation-to-celebration-decrypting-electronics-price-inflation-on-amazon" />} />
          <Route path='/blog/retail-trends-to-look-out-for-in-2024/' element={<Redirect toUrl="/research-articles/top-5-game-changing-retail-trends-of-2024" />} />
          <Route path='/blog/sciative-solutions-recognized-with-the-best-of-badges-from-gartner/' element={<Redirect toUrl="/research-articles/sciative-solutions-recognized-with-the-best-of-badges-from-gartner" />} />
          <Route path='/blog/shaping-fairness-the-art-of-dynamic-pricing/' element={<Redirect toUrl="/research-articles/shaping-fairness-the-art-of-dynamic-pricing" />} />
          <Route path='/blog/hands-off-profits-up-let-ai-take-the-pricing-wheel/' element={<Redirect toUrl="/research-articles/ai-pricing-revolution-unleash-profits-with-adaptive-pricing" />} />
          <Route path='/blog/ride-the-wave-of-digitisation-a-win-win-for-bus-businesses-customers/' element={<Redirect toUrl="/research-articles/ride-the-wave-of-digitisation-a-win-win-for-bus-businesses-customers" />} />
          <Route path='/blog/stars-prices-and-profits-how-your-ratings-harness-our-potential/' element={<Redirect toUrl="/research-articles/stars-prices-and-profits-how-online-reviews-drive-sales-and-profits" />} />
          <Route path='/blog/impact-of-gender-pricing-on-your-wallets/' element={<Redirect toUrl="/research-articles/impact-of-gender-pricing-on-your-wallets" />} />
          <Route path='/brio-retail-pricing-optimization-automation/' element={<Redirect toUrl="/research-articles/category/Retail" />} />
          <Route path='/viaje-ai-for-travel/' element={<Redirect toUrl="/research-articles/category/Travel" />} />
          <Route path='/viaje-ai-for-air/' element={<Redirect toUrl="/research-articles/category/Travel" />} /> 
          <Route path='/blog/competitive-realities-of-the-airline-industry/' element={<Redirect toUrl="/research-articles/competitive-realities-of-the-airline-industry" />} />
          <Route path='/blog/top-6-airline-pricing-trends-2024/' element={<Redirect toUrl="/research-articles/top-6-airline-pricing-trends-2024" />} />
          <Route path='/blog/taking-off-into-the-future-the-evolution-of-airfare-pricing-strategies/' element={<Redirect toUrl="/research-articles/taking-off-into-the-future-the-evolution-of-airfare-pricing-strategies" />} />
          <Route path='/blog/price-elasticity-in-retail-industry/' element={<Redirect toUrl="/research-articles/price-elasticity-in-the-retail-industry" />} />
          <Route path='/blog/rule-based-pricing-vs-ai-powered-pricing-which-smart-dynamic-pricing-strategy-is-best-for-your-business/' element={<Redirect toUrl="/research-articles/rule-based-pricing-vs-ai-powered-pricing-which-smart-dynamic-pricing-strategy-is-best-for-your-business" />} />
          <Route path='/blog/how-amazon-prime-day-affected-prices-and-discounts/' element={<Redirect toUrl="/research-articles/how-amazon-prime-day-affected-prices-and-discounts" />} />
          <Route path='/blog/how-to-gain-an-edge-in-the-market-with-competitive-pricing/' element={<Redirect toUrl="/research-articles/how-to-gain-an-edge-in-the-market-with-competitive-pricing" />} />
          <Route path='/blog/listing-content-quality-management-why-your-brand-needs-it/' element={<Redirect toUrl="/research-articles/listing-content-quality-management-why-your-brand-needs-it" />} />
          <Route path='/blog/seasonal-shift-monsoons-impact-on-electronics-prices-from-summer-to-monsoon/' element={<Redirect toUrl="/research-articles/seasonal-shift-monsoons-impact-on-electronics-prices-trends-from-summer-to-monsoon" />} />
          <Route path='/blog/dynamic-pricing-101-the-ultimate-guide/' element={<Redirect toUrl="/research-articles/dynamic-pricing-101-the-ultimate-guide" />} />
          <Route path='/blog/how-customer-voice-is-critical-for-brand-success/' element={<Redirect toUrl="/research-articles/how-voice-of-customer-is-critical-for-brand-success" />} />
          <Route path='/blog/beyond-holiday-highs-decoding-the-airline-pricing-puzzle-beyond-seasonal-surges/' element={<Redirect toUrl="/research-articles/decoding-the-airline-ticket-pricing-puzzle-beyond-seasonal-surges" />} />
          <Route path='/blog/heres-how-dynamic-pricing-helped-e-commerce-brands-boost-their-revenue/' element={<Redirect toUrl="/research-articles/how-dynamic-pricing-helped-e-commerce-brands-for-revenue-maximization" />} />
          <Route path='/blog/7-reasons-every-retail-company-needs-an-automated-ai-pricing-solution/' element={<Redirect toUrl="/research-articles/7-reasons-every-retail-company-needs-an-automated-pricing-tool" />} />
          <Route path='/blog/from-confusion-to-clarity-flight-inventory-visibility/' element={<Redirect toUrl="/research-articles/need-of-ai-powered-solutions-for-flight-inventory-visibility" />} />
          <Route path='/blog/shop-n-roll-real-time-retail/' element={<Redirect toUrl="/research-articles/all-about-real-time-retail-pricing-strategy" />} />
          <Route path='/blog/how-pricing-impact-your-customer-life-time-value/' element={<Redirect toUrl="/research-articles/how-pricing-impacts-your-customer-lifetime-value-cltv" />} />
          <Route path='/blog/capacity-optimization-using-dynamic-pricing-strategies/' element={<Redirect toUrl="/research-articles/inventory-optimisation-with-dynamic-pricing" />} />
          <Route path='/blog/dynamic-pricing-social-impact-altering-consumers-perception/' element={<Redirect toUrl="/research-articles/social-impact-of-dynamic-pricing-altering-consumers-perceptions" />} />
          <Route path='/blog/the-economics-of-hallowen-2023/' element={<Redirect toUrl="/research-articles/the-economics-of-halloween-season-2023" />} />
          <Route path='/blog/tag-youre-it-fashions-new-playmate-ai-tagging/' element={<Redirect toUrl="/research-articles/the-impact-of-ai-tagging-on-online-shopping" />} />
          <Route path='/blog/price-optimization-utilizing-ai-and-human-judgment/' element={<Redirect toUrl="/research-articles/retail-price-optimization-utilizing-ai-and-human-judgment" />} />
          <Route path='/blog/seasonality-demand-fluctuations-in-india-and-western-countries/' element={<Redirect toUrl="/research-articles/analyzing-seasonal-demand-fluctuations-in-india-and-western-countries" />} />
          <Route path='/blog/brand-dilution-due-to-incorrect-pricing/' element={<Redirect toUrl="/research-articles/brand-dilution-due-to-incorrect-pricing" />} />
          <Route path='/blog/progressing-in-pricing-how-pricing-systems-have-adapted-to-the-modern-business/' element={<Redirect toUrl="/research-articles/pricing-strategy-evolution-ais-role-in-market" />} />
          <Route path='/blog/why-ai-driven-smart-sponsorship-planning-is-critical-for-your-brands-triumph/' element={<Redirect toUrl="/research-articles/why-ai-driven-smart-sponsorship-planning-is-critical-for-your-brands-triumph" />} />
          <Route path='/blog/training-ai-powered-machines-to-learn-the-human-way/' element={<Redirect toUrl="/research-articles/retail-price-optimization-utilizing-ai-and-human-judgment" />} />
          <Route path='/blog/can-effective-inventory-planning-propel-your-brand-to-success/' element={<Redirect toUrl="/research-articles/efficient-inventory-management-why-its-vital" />} />
          <Route path='/blog/eleventh-hour-booking-fare-logics/' element={<Redirect toUrl="/research-articles/cracking-the-code-of-last-minute-flight-fares" />} />
          <Route path='/blog/where-the-world-meets-the-wallet-decoding-geographical-dynamics-in-airline-pricing/' element={<Redirect toUrl="/research-articles/decod-geographical-pricing-dynamics-in-airline" />} />
          <Route path='/blog/how-major-events-shape-the-travel-landscape/' element={<Redirect toUrl="/research-articles/how-major-events-shape-demand-for-travel-industry" />} />
          <Route path='/blog/survival-of-the-boldest-corporate-cannibalism/' element={<Redirect toUrl="/research-articles/survival-of-the-boldest-corporate-cannibalism" />} />
          <Route path='/blog/black-fridays-origins-from-chaos-to-commerce/' element={<Redirect toUrl="/research-articles/unveiling-black-friday-revealing-the-peak-event-in-retail" />} />
          <Route path='/blog/black-friday-bonanza-2023-a-shoppers-delight-in-india/' element={<Redirect toUrl="/research-articles/black-friday-in-india-sales-trends-insights" />} />
          <Route path='/blog/understanding-the-price-volume-balancing-act-how-pricing-strategy-shapes-profits/' element={<Redirect toUrl="/research-articles/price-volume%20balancing%20act:%20how%20pricing%20strategy%20shapes%20profits" />} />
          <Route path='/blog/winning-with-pricing-strategies/' element={<Redirect toUrl="/research-articles/pricing-strategies-for-a-competitive-edge-amazon" />} />
          <Route path='/blog/unlocking-the-amazon-buy-button-with-artificial-intelligence/' element={<Redirect toUrl="/research-articles/unlocking-the-amazon-buy-button-with-artificial-intelligence" />} />
          <Route path='/blog/best-tips-to-deal-with-pricing-during-inflation/' element={<Redirect toUrl="/research-articles/best-tips-to-deal-with-pricing-inflation" />} />
          {/* <Route path='/blog/heres-how-brio-can-help-retail-brands-identify-map-violations/' element={<Redirect toUrl="" />} /> */}
          <Route path='/blog/tips-challenges-of-change-management-in-the-airline-pricing-industry/' element={<Redirect toUrl="/research-articles/airline-pricing-change-management-insights" />} />
          <Route path='/blog/psychology-of-pricing-how-customer-shapes-pricing-strategies/' element={<Redirect toUrl="/research-articles/how-consumers-psychology-shape-pricing" />} />
          <Route path='/blog/6-premium-pricing-strategies-by-luxury-brands/' element={<Redirect toUrl="/research-articles/6-premium-pricing-strategies-by-luxury-brands/" />} />
          <Route path='/blog/key-insights-to-help-you-prepare-for-2023-pricing-strategies/' element={<Redirect toUrl="/research-articles/key-insights-to-help-you-prepare-for-2023-retail-pricing-strategies" />} />
          <Route path='/blog/how-does-ai-powered-pricing-help-reduce-customer-churn/' element={<Redirect toUrl="/research-articles/how-does-ai-powered-pricing-help-reduce-customer-churn" />} />
          <Route path='/blog/how-dynamic-pricing-can-revive-the-future-of-stus/' element={<Redirect toUrl="/research-articles/transforming-stu-futures-dynamic-pricing-strategies" />} />
          <Route path='/blog/how-dynamic-pricing-can-increase-profitability-in-railways/' element={<Redirect toUrl="/research-articles/how-dynamic-pricing-can-increase-profitability-for-indian-railways" />} />
          <Route path='/blog/chatgpt-augments-ar-transforming-retail-experience/' element={<Redirect toUrl="/research-articles/chatgpt-augmented-reality-in-retail-revolution" />} />
          <Route path='/blog/the-decoy-effect-influencing-customers-choices-through-pricing/' element={<Redirect toUrl="/research-articles/decoy-effect-influencing-customers-choices" />} />
          <Route path='/blog/7-reasons-every-retail-company-needs-an-automated-ai-pricing-solution-2/' element={<Redirect toUrl="/research-articles/7-reasons-every-retail-company-needs-an-automated-pricing-tool" />} />
          <Route path='/blog/chatgpt-improving-customer-relations-in-the-retail-industry/' element={<Redirect toUrl="/research-articles/chatgpt-improving-customer-relations-in-the-retail-industry" />} />
          <Route path='/blog/memes-and-ai-in-marketing-exploring-the-branding-potential-for-genz/' element={<Redirect toUrl="/research-articles/memes-and-ai-in-marketing-explore-the-brand-potential-for-genz" />} />
          <Route path='/blog/best-last-minute-holiday-sales-strategies-in-the-usa-for-2022/' element={<Redirect toUrl="/research-articles/best-last-minute-holiday-sales-strategies-in-the-usa-for-2022" />} />
          <Route path='/blog/top-7-technological-retail-trends-for-2023/' element={<Redirect toUrl="/research-articles/top-7-tech-retail-trends-ai-vr-chatbots-more" />} />
          <Route path='/blog/google-io-google-gets-a-makeover-with-ai/' element={<Redirect toUrl="/research-articles/google-io-google-gets-a-makeover-with-ai" />} />
          <Route path='/blog/blog-post-3-2/' element={<Redirect toUrl="/research-articles/how-the-coronavirus-pandemic-has-intensified-the-value-of-dynamic-pricing-for-e-commerce-businesses" />} />

          <Route path='/case-study/brio-enabled-a-beauty-brand-to-reduce-its-app-uninstalls-by-15' element={<Redirect toUrl={AllRoutes.casestudies} />} /> 
          <Route path='/case-study/rule-based-pricing-vs-ai-powered-pricing-which-smart-dynamic-pricing-strategy-is-best-for-your-business-2' element={<Redirect toUrl={AllRoutes.casestudies} />} /> 

          <Route path='/roadahead-bus/the-young-vision-shaping-the-future-of-the-bus-industry/' element={<Redirect toUrl="/road-ahead-talks/the-young-vision-shaping-the-future-of-the-bus-industry" />} />
          <Route path='/roadahead-bus/in-conversation-with-president-of-boci/' element={<Redirect toUrl="/road-ahead-talks/in-conversation-with-president-of-boci" />} />
          <Route path='/roadahead-bus/in-conversation-with-president-of-boci-test/' element={<Redirect toUrl="/road-ahead-talks/in-conversation-with-president-of-boci" />} /> 
          <Route path='/roadahead/webinar-air/' element={<Redirect toUrl={AllRoutes.roadaheadtalks} />} />
          <Route path='/roadahead/webinar-retail/' element={<Redirect toUrl={AllRoutes.roadaheadtalks} />} />
          <Route path='/roadahead-bus/webinar-1/' element={<Redirect toUrl={AllRoutes.roadaheadtalks} />} />
          <Route path='/roadahead-air/webinar-air-2/' element={<Redirect toUrl={AllRoutes.roadaheadtalks} />} />
          {/* <Route path='/case-study/brio-enabled-a-beauty-brand-to-reduce-its-app-uninstalls-by-15/' element={<Redirect toUrl={AllRoutes.roadaheadtalks} />} />
          <Route path='/case-study/rule-based-pricing-vs-ai-powered-pricing-which-smart-dynamic-pricing-strategy-is-best-for-your-business-2/' element={<Redirect toUrl="" />} />*/}
          <Route path='/case-study/' element={<Redirect toUrl={AllRoutes.casestudies} />} /> 
         
            
          {/* Older routes */}
          <Route path='/solutions/media' element={<Redirect toUrl={AllRoutes.home} />} /> 
          <Route path='/resources' element={<Redirect toUrl='/research-articles' />} /> 
          <Route path='/analytical-reports' element={<Redirect toUrl='/case-studies' />} /> 
          <Route path='/news' element={<Redirect toUrl={AllRoutes.news} />} /> 
          <Route path='/contact-us' element={<Redirect toUrl='/contact' />} /> 
          <Route path='/dynamic-pricing' element={<Redirect toUrl={`/research-articles/category/Dynamic Pricing`} />} /> 
          <Route path='/competition-intelligence' element={<Redirect toUrl={`/research-articles/category/Competition Intelligence`} />} /> 
          <Route path='/retail-resources/' element={<Redirect toUrl="/research-articles/category/Retail" />} /> 
          <Route path='/e-commerce/' element={<Redirect toUrl="/research-articles/category/E-Commerce" />} /> 
          <Route path='/intercity-coaches/' element={<Redirect toUrl="/research-articles/category/Intercity Coaches" />} /> 
          <Route path='/price-elasticity/' element={<Redirect toUrl="/research-articles/category/Price Elasticity" />} /> 
          <Route path='/videos' element={<Redirect toUrl={AllRoutes.roadaheadtalks} />} /> 
          <Route path='/author/admin' element={<Redirect toUrl={AllRoutes.home} />} /> 
          <Route path='/author/post_editor' element={<Redirect toUrl={AllRoutes.home} />} /> 
          
          {/* new blogs end */}

          {/* 404 Error route */}
          <Route path='*' element={<CommonLayout fixed={true}><NotFound /></CommonLayout>} />


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
