import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/header.scss';
import sciativeLogo from '../../assets/img/sciative-logo.png'
import brioLogo from '../../assets/img/brio.png';
import viajeLogo from '../../assets/img/viaje-black.png';
import zettaLogo from '../../assets/img/hotels/zettapriceblack.png';
import { Nav, Navbar, Container, NavDropdown, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import Form from '../Forms/Form';
import AllRoutes from '../../AllRoutes';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Header = ({ fixed }) => {
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [url, setUrl] = useState('');

    useEffect(() => {
        fetch_research_article();
    }, [])

    const fetch_research_article = async () => {
        try {
            const response = await axios.get(`/get_latest_blog`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setTitle(response.data.title)
                setUrl(response.data.url)
                setDate(response.data.publishedDate)
            }
        } catch (err) {

        }
    }
    const [open, setOpen] = useState(false);
    const [dropdowns, setDropdowns] = useState({
        show1: false,
        show2: false,
        show3: false,
    });

    const toggleDropdown = (dropdownName) => {
        setDropdowns(prevState => ({
            ...prevState,
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    const handleClick = (dropdownName) => {
        if (window.innerWidth <= 768) { // Check for mobile and tablet devices
            toggleDropdown(dropdownName);
        }
    };

    const handleMouseEnter = (dropdownName) => {
        if (window.innerWidth > 768) { // Check for desktop devices
            setDropdowns({ ...dropdowns, [dropdownName]: true });
        }
    };

    const handleMouseLeave = (dropdownName) => {
        if (window.innerWidth > 768) { // Check for desktop devices
            setDropdowns({ ...dropdowns, [dropdownName]: false });
        }
    };

    const props = {
        template: 1,
        form_name: "navbar-book-a-demo",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "message", required: true },
        ],
        title: "Book a Demo",
        content_upper: "Curious to explore AI pricing or knowing more about us? Send us a short message, our experts will contact you at the earliest.",
        content_lower: "Let's Price Right!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            setOpen(false)
            if (res.status == "success"){
                toast.success('Form submitted successfully!');
            } else {
                toast.error(res.data)
            }
        }
    }

    return (
      < section className='nav_nav'>
<nav className={`main_navbar ${!fixed ? '' : 'p-fixed'}`} id='main_navbar'>
  <div className="">
    <div className="nav_1 wrapper">
    <div className="logo">
        <a href={AllRoutes.home}>
        <img src={sciativeLogo} alt="Sciative Logo" id="brand-logo"/>
        </a>
    </div>
    {/* <input type="radio" name="slider" id="menu-btn" />
    <input type="radio" name="slider" id="close-btn" /> */}
    <ul className="nav-links">
      {/* <label for="close-btn" className="btn close-btn"><i className="fas fa-times"></i></label> */}
      <li className='drop-items'>
        <a href="#" className="desktop-item">INDUSTRIES</a>
        {/* <input type="checkbox" id="showMega" />
        <label for="showMega" className="mobile-item">SOLUTIONS</label> */}
        <div className="mega-box">
        <div className="content row">
                    <div className="col-md-4 row_2">
                        <div className="row">
                            <div className="col-md-12  mb-44">
                                <a href={AllRoutes.retail} className='border_div'>
                                    <h5 className="nav_head active"><img src={brioLogo} alt="Brio logo" />For Retail</h5>
                                    <p className='first_p'>Uncover strategies for staying ahead and driving growth</p>
                                </a>
                            </div>
                            {/* col-12-end */}
                          
                        </div>
                        {/* inner row end */}
                    </div>
                    {/* col-4end */}
                    <div className="col-md-4 row_2">
                        <div className="row">
                            <div className="col-md-12  mb-44">
                                <a href={AllRoutes.travel} className='border_div'>
                                    <h5 className="nav_head active"><img src={viajeLogo} alt="Viaje logo" />For Travel (Buses)</h5>
                                    <p className='first_p'>Learn how to get ahead of trends and fuel your success</p>
                                </a>
                                
                            </div>
                            {/* col-12-end */}
                            
                        </div>
                        {/* inner row end */}
                    </div>
                    {/* col-4end */}
                    <div className="col-md-4 row_2">
                        <div className="row">
                            <div className="col-md-12  mb-44">
                                <a href={AllRoutes.hotel} className='border_div'>
                                    <h5 className="nav_head zetta-logo-head active"><img src={zettaLogo} alt="Zettaprice logo" />For Hotels</h5>
                                    <p className='first_p'>Discover how to enhance profitability and elevate guest experiences</p>
                                </a>
                                
                            </div>
                            {/* col-12-end */}
                            
                        </div>
                        {/* inner row end */}
                    </div>
                    {/* col-4 end */}
            </div>
      
        </div>
      </li>
      {/* solution over */}
      <li className='drop-items'>
        <a href="#" className="desktop-item">KNOWLEDGE WAREHOUSE</a>
        {/* <input type="checkbox" id="showMega" />
        <label for="showMega" className="mobile-item">SOLUTIONS</label> */}
        <div className="mega-box">
            <div className="content row">
                    <div className="col-md-4 row_2">
                        <div className="row">
                            <div className="col-md-12  mb-44">
                                <a href={AllRoutes.researcharticles} className='border_div'>
                                    <h5 className="nav_head active">Research Articles</h5>
                                    <p className='first_p'>Key insights and comprehensive knowledge brought to you by Sciative</p>
                                </a>
                            </div>
                            {/* col-12-end */}
                            <div className="col-md-12 ">
                                <a href={AllRoutes.casestudies} className='border_div'>
                                    <h5 className="nav_head active">Case Studies</h5>
                                    <p className='first_p'>Get valuable insights with our industry analysis and expert opinions</p>
                                </a>
                                
                            </div>
                            
                            {/* col-12-end */}
                           
                        </div>
                        {/* inner row end */}
                    </div>
                    {/* col-4end */}
                    <div className="col-md-4 row_2">
                        <div className="row">
                            <div className="col-md-12  mb-44">
                                <a href={AllRoutes.routereports} className='border_div'>
                                    <h5 className="nav_head active">Route Reports</h5>
                                    <p className='first_p'>Understand the global footprint of travelers across different markets</p>
                                </a>
                                
                            </div>
                            {/* col-12-end */}
                            
                            <div className="col-md-12 ">
                                <a href={AllRoutes.roadaheadnewsletter} className='border_div'>
                                    <h5 className="nav_head active">Road Ahead Newsletter</h5>
                                    <p className='first_p'>Our monthly travel industry insights and updates</p>
                                </a>
                                
                            </div>

                            
                            {/* col-12-end */}
                        </div>
                        {/* inner row end */}
                    </div>
                    {/* col-4end */}
                    <div className="col-md-4 row_2">
                        <div className="row">
                            <div className="col-md-12 ">
                                <a href={AllRoutes.roadaheadtalks} className='border_div'>
                                    <h5 className="nav_head active">Road Ahead Talks</h5>
                                    <p className='first_p'>Our podcast series with trailblazing guests from across industries </p>
                                </a>
                                
                            </div>
                             
                            <div className="col-md-12 ">
                                <a href={AllRoutes.glossary} className='border_div'>
                                    <h5 className="nav_head active">Glossary</h5>
                                    <p className='first_p'>Understand definitions and explanations for key concepts</p>
                                </a>
                                
                            </div>

                            {/* <div className="col-md-12 ">
                            {title && url && date &&
                                <a href={`/research-articles/${url}`} className='border_div reasearch'>
                                    <h5 className="nav_head research-arc active">{title}</h5>
                                    <p className='text-muted first_p dates'>{moment(date).format("Do MMM, YYYY")}</p>
                                </a>
                            }
                                
                            </div> */}
                            {/* col-12-end */}
                            {/* <div className="col-md-12  mb-44">
                                <a  className='border_div'>
                                    <h5 className="nav_head active">Videos</h5>
                                    <p className='first_p'>Dive into Sciative’s industry presence showcasing our pricing expertise</p>
                                </a>
                                
                            </div> */}
                            {/* col-12-end */}
                           
                        </div>
                        {/* inner row end */}
                    </div>
                    {/* col-4 end */}
            </div>
      
        </div>
      </li>
      {/* kw over over */}
      <li className='drop-items'>
        <a href="#" className="desktop-item">CAREERS</a>
        {/* <input type="checkbox" id="showMega" />
        <label for="showMega" className="mobile-item">SOLUTIONS</label> */}
        <div className="mega-box">
            <div className="content row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12 border_div mb-44">
                            <a href={AllRoutes.careers} className=''>
                                <h5 className="nav_head active">Jobs</h5>
                                <p className='first_p'>Discover your next career opportunity and unlock your potential with our diverse range of job listings</p>
                            </a>
                            
                        </div>
                        {/* col-12-end */}
                        <div className="col-md-12 border_div">
                            <a href="https://apti.sciative.com/" target='_blank' >
                                <h5 className="nav_head active">Aptitude Portal</h5>
                                <p className='first_p'>Sharpen your skills and conquer challenges with our comprehensive aptitude portal</p>
                            </a>
                            
                        </div>
                        {/* col-12-end */}
                    </div>
                    {/* inner row end */}
                </div>
                <div className="col-md-6">
                    <div className="img_header job_header">
                            
                    </div>
                </div>
                {/* col-6 end */}
            </div>
        </div>
      </li>
      {/* solution over */}
      
      <li className='drop-items'>
        <a href="#" className="desktop-item">ABOUT US</a>
        {/* <input type="checkbox" id="showMega" />
        <label for="showMega" className="mobile-item">SOLUTIONS</label> */}
        <div className="mega-box">
            <div className="content row">
                <div className="col-md-4 row_2">
                    <div className="row">
                        <div className="col-md-12  mb-44">
                            <a href={AllRoutes.about} className='border_div'>
                                <h5 className="nav_head active">About us</h5>
                                <p className='first_p'>Know who we are and what we do</p>
                            </a>
                        </div>
                        {/* col-12-end */}
                        <div className="col-md-12 ">
                            <a href={AllRoutes.partner} className='border_div'>
                                <h5 className="nav_head active">Partner With Us</h5>
                                <p className='first_p'>Join hands in adding new revenue streams to your business portfolios</p>
                            </a>
                            
                        </div>
                        {/* col-12-end */}
                    </div>
                    {/* inner row end */}
                </div>
                {/* col-4end */}
                <div className="col-md-4 row_2">
                    <div className="row">
                        <div className="col-md-12  mb-44">
                            <a href={AllRoutes.investors} className='border_div'>
                                <h5 className="nav_head active">Investor Resources</h5>
                                <p className='first_p'>Walk with us on our journey in becoming the pioneering AI-based pricing company of the world.</p>
                            </a>
                            
                        </div>
                        {/* col-12-end */}
                        <div className="col-md-12 ">
                            <a href={AllRoutes.news} className='border_div'>
                                <h5 className="nav_head active">Sciative in Media</h5>
                                <p className='first_p'>Dive into Sciative’s industry presence showcasing our pricing expertise</p>
                            </a>
                            
                        </div>
                        {/* col-12-end */}
                    </div>
                    {/* inner row end */}
                </div>
                {/* col-4end */}
                <div className="col-md-4">
                    <div className="img_header abt_header">
                    </div>
                </div>
                {/* col-4 end */}
            </div>
        </div>
      </li>
      <li className='link-yellow'><a href={AllRoutes.contact}>CONTACT US</a></li>
      {/* about over */}
  
    </ul>
    </div>
    {/* nav 1 end */}
   {/* <button className='yellow-button' onClick={() => setOpen(true)}>Book A Demo </button> */}
  </div>
</nav>

<Navbar collapseOnSelect expand="xl" className={`bg-body-tertiary ${!fixed ? '' : 'p-fixed'}`}>
      <Container>
        <Navbar.Brand href={AllRoutes.home}> <img src={sciativeLogo} alt="Sciative Logo" id="brand-logo-2"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" /> 
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <NavDropdown title="Industries" id="collapsible-nav-dropdown">
             <NavDropdown.Item href={AllRoutes.retail}>
              Retail
              </NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.travel}>Travel (Buses)</NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.hotel}>Hotels</NavDropdown.Item>
             
            </NavDropdown>
            <NavDropdown title="Knowledge Warehouse" id="collapsible-nav-dropdown2">
             <NavDropdown.Item href={AllRoutes.researcharticles}>
             Research Articles
              </NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.roadaheadtalks}>Road Ahead Talks</NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.roadaheadnewsletter}>Road Ahead Newsletter</NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.routereports}>Route Reports</NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.casestudies}>Case Studies</NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.glossary}>Glossary</NavDropdown.Item>
              {/* <NavDropdown.Item >Videos</NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown title="Careers" id="collapsible-nav-dropdown3">
             <NavDropdown.Item href={AllRoutes.careers}>
            Jobs
              </NavDropdown.Item>
              <NavDropdown.Item href="https://apti.sciative.com/" target='_blank'>Aptitude Portal</NavDropdown.Item>
             
            
             
            </NavDropdown>
            
            <NavDropdown title="About Us" id="collapsible-nav-dropdown4">
             <NavDropdown.Item href={AllRoutes.about}>
                About Us
              </NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.partner} target='_blank'> Partner With Us</NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.investors}>
              Investor Resources
              </NavDropdown.Item>
              <NavDropdown.Item href={AllRoutes.news}>Sciative in Media</NavDropdown.Item>
             
             
            </NavDropdown>
            <Nav.Link href={AllRoutes.contact}>Contact Us</Nav.Link>
          </Nav>
          {/* <Nav>
          <button className='yellow-button' onClick={() => setOpen(true)}>Book A Demo </button>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>



            <Modal
                show={open}
                onHide={() => setOpen(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassNameName="remove-white-bg"
                backdrop="static"
                centered
            >
                <Modal.Body classNameName="p-0">
                    <CloseIcon onClick={() => setOpen(false)} classNameName='modal-close-icon'/>
                    <Form {...props} />
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default Header;
